<template>
  <div id="container">
    <v-card :class="{ card: true, mobile: isMobile }" :style="'height:'+fullHeight+'px;'">
      <img
        class="clo"
        width="31px"
        height="31px"
        @click="closeDialog"
        src="@/assets/btn_close.png" 
        alt=""
      />
      <h3>{{ $t("lucky_title_count") }}</h3>
      <div class="top">
        <div
          v-for="item in dataList"
          :key="item.id"
          class="list d-flex flex-row justify-space-between"
        >
          <div class="left d-flex flex-row">
            <img src="@/assets/image/pool_chip.png" alt="" />
            <div class="num ml-2 mt-1" :style="{color: item.luck ==true ? '#7700ff!important' : ''}">{{item.number}}</div>
          </div>
          <img
          v-if="item.luck == true"
          class="pool"
          src="@/assets/image/icon_pool.png"
          alt=""
          width="28px"
          height="25px"
          style="margin-left:18px;"
        />
        <div style="width:34px;height:34px;margin-left:-20px;" v-if="item.luck !== true"></div>
        <div class="time" :style="{color: item.luck ==true ? '#7700ff!important' : ''}">{{ item.createTime }}</div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import api from "@/api";
export default {
  data: () => ({
    dataList: [],
    luckyid:'',
    isLoadList : true,
    fullHeight: document.documentElement.clientHeight
  }),
  props: {
            luckyID: {
                type: Number
            }
        },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  	watch: {
			fullHeight (val) {//监控浏览器高度变化
				if(!this.timer) {
					this.fullHeight = val
					this.timer = true
					let that = this
					setTimeout(function (){
						that.timer = false
					},400)
				}
				
			}
		},
   mounted() {
        this.init();
        this.get_bodyHeight()
  
  },
  methods: {

    async init(value){

      this.dataList = [];
   
    if(value){
      this.luckyid = value
    }else{
      this.luckyid = this.luckyID
    }

    if(!this.isLoadList)
    {
      return;
    }
    this.isLoadList = false;
      let params = {
      activityId: this.luckyid,
      owner: this.$store.state.did,
      //owner:'iaa1dqfet703fj838m9u3yq68h2rczt8fr3e57agal',
    };
    let res = await api.home.joinInfoByOwner(params);
    //this.dataList = res.data.list;

      res.data.list.forEach(async v => {
       
        this.dataList.push({
                      luck:v.luck,
                       number: v.number,
                       createTime: this.timestampToTime(v.createTime)
                   });
              })

      this.isLoadList = true
     
    },
    	get_bodyHeight () {//动态获取浏览器高度
				const that = this
				window.onresize = () => {
					return (() => {
						window.fullHeight = document.documentElement.clientHeight
						that.fullHeight = window.fullHeight
					})()
				}
			},

    
    //关闭弹框，发送open值
    closeDialog() {
      this.$emit("getOpen", false);
    },
    timestampToTime(time) {
      let date = new Date(time);
      let Y = date.getFullYear() + "-";
      let M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      let D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      let h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      let m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      let s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      let strDate = Y + M + D + h + m + s;
      return strDate;
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  width: 335px;
  // max-height: 900px;
  //height: 750px;
  padding-top:29px;

  // min-height: 600px;
  .clo {
    width: 25px;
    height: 25px;
    display: block;
    position: absolute;
    right: 15px;
    top: 18px;
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
  }
  h3 {
    font-family:Helvetica;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    line-height: 20px;
    letter-spacing: 0px;
    color: #270645;
    margin-left: 21px;
  }

  .top {
    // min-height: 500px;
    height:100%;
    overflow: auto;
    padding-top:29px;

    .list {
      margin-left: 20px;
      margin-right:20px;
      margin-bottom:9px;

      .left {
        img {
          width: 24px;
          height: 25px;
          display: block;
        }
        .num {
          // width: 51px;
          height: 10px;
          font-family:Helvetica;
          font-size: 12px;
          font-weight: bold;
          font-stretch: normal;
          // line-height: 100px;
          letter-spacing: 0px;
          color: #270645;
        }
      }
      .time{
          height: 11px;
        font-family:Helvetica;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        // line-height: 100px;
        letter-spacing: 0px;
        color: #270645;
      }
    }
  }

//   &.mobile {
//   }
}

.fansPhoto {
  border-radius: 50%;
}

.v-card {
  box-shadow: 0px 0px 9px 0px rgb(0 0 0 / 9%) !important;
}

.mobile.card {
  width: 350px !important;
  height: 600px;
}
</style>