<template>
  <div :class="{ lottery: true, mobile: isMobile }">
    <div class="title d-flex flex-column">
      <div class="alltit d-flex flex-row">
        <div class="">
          <p>{{ $t("lucky_title_activity") }}</p>
        
        </div>
      </div>
      <div class="cont d-flex flex-row justify-space-between">
        <div class="desc">{{ $t("lucky_title") }}</div>
      </div>
    </div>

    <div class="lotterylist">
      <div v-for="(item, index) in LotteryList" :key="index">
        <div class="card" :class="{ smallcard: LotteryList.length > 2 }" @click="toLotteryDetail(item.id)">
          <div class="contant d-flex flex-lg-column flex-column">
            <div class="title mt-11">
            
               <img :src="
                require('@/assets/icons/' +
                  getChainByName(item.coinDenom)+
                  '.png')
          " style="width: 35px; height: 35px; margin-bottom: -5px" />
              <span class="coinname">{{ getChainNameByDenom(item.coinDenom )}} Pool</span>
            </div>
            <div class="prize mt-2">{{ item.activityName }}</div>
            <div class="prizeTitle mt-2">{{ $t("lucky_raffle_begin") }}</div>
            <div class="amount">{{ item.prizePool }}</div>
            <div class="prize ">{{ $t("lucky_raffle_countdown") }}</div>
            <div class="timer d-flex flex-lg-row">
              <div class="days">
                <div class="top d-flex flex-row">
                  <span class="mr-1">{{ item.format.d1 }}</span>
                  <span class="mr-2">{{ item.format.d2 }}</span>
                </div>
                <div class="bom">{{ $t("Day") }}</div>
              </div>
              <div class="mao">:</div>
              <div class="hours">
                <div class="top d-flex flex-row">
                  <span class="mr-1 ml-2">{{ item.format.h1 }}</span>
                  <span class="mr-2">{{ item.format.h2 }}</span>
                </div>
                <div class="bom">{{ $t("Hr") }}</div>
              </div>
              <div class="mao">:</div>
              <div class="minutes">
                <div class="top d-flex flex-row">
                  <span class="ml-2 mr-1">{{ item.format.m1 }}</span>
                  <span class="mr-2">{{ item.format.m2 }}</span>
                </div>
                <div class="bom">{{ $t("Min") }}</div>
              </div>
              <div class="mao">:</div>
              <div class="seconds">
                <div class="top d-flex flex-row">
                  <span class="ml-2 mr-1">{{ item.format.s1 }}</span>
                  <span class="">{{ item.format.s2 }}</span>
                </div>
                <div class="bom">{{ $t("Sec") }}</div>
              </div>
            </div>
            <div class="lotteries" @click.stop="toLottery(item.id)">{{ $t("myLotteries") }}：{{item.joinNumber}} ></div>
          </div>
        </div>
      </div>
    </div>
     <div
      v-if="openlottery"
      style="position: fixed; right: 20px;top:0px; bottom: -20px; z-index: 99999"
    >
      <lottery  ref="lottery" v-if="openlottery" @getOpen="OpenLot" :luckyID ="luckyId" ></lottery>
    </div>
  </div>
</template>

<script lang="js">
import lottery from "@/components/popup/lottery.vue";
import api from "@/api";

  export default {
    name: "lotteryList",
    components: {lottery },
    props: {
     
    },
    data: function() {
      return {
      openlottery: false,
      luckyId:'',
      isShowLoading: false,
      pageNumber: 0,
      pageSize: 10,
      isLoading:false,
      LotteryList:[
              // {
              //     id:1,
              //     name:"IRIS Pool",
              //     age:21,
              //     prize:'prize',
              //     amount:'3,540.825',
              //     timestampToTime:'1627894843000'
          
              // },
              // {
              //     id:2,
              //     name:"IRIS Pool",
              //     age:21,
              //      prize:'prize',
              //      amount:'1862.05',
              //      timestampToTime:'1659506453000'
              // },
              // {
              //     id:3,
              //     name:"IRIS Pool",
              //     age:21,
              //      prize:'prize',
              //      amount:'3,540.825',
              //      timestampToTime:'1659592525000',
              // },
              // {
              //     id:4,
              //     name:"IRIS Pool",
              //     age:21,
              //      prize:'prize',
              //      amount:'3,540.825',
              //      timestampToTime:'1659679276000'
              // },        
          ],
       
      };
    },
     computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
    mounted() {
       window.eventBus.$on('LangChange', this.onLangChange);
     this.getTopInfo();
     
      this.Time();
    },
    methods: {
      
       async getTopInfo(){
      let params = {
       owner: this.$store.state.did,
     //owner: "iaa1dqfet703fj838m9u3yq68h2rczt8fr3e57agal",
    
      };
       let resTopInfo = await api.home.luckInfo(params);
      if(resTopInfo.success == true){
         this.TopInfo = resTopInfo.data
      this.LotteryList = resTopInfo.data
       this.LotteryList.forEach((v)=>{
         console.log("wxl --- 444",v.endTime)
        v.format = this.countDown(v.endTime);
      
      })
   
      }
     
     
    },

    toLotteryDetail(id){
     
      this.$router.push({ name: "Lotterydetail",query:{
        activityId:id
      } });
    },
    toMyLottery(){
      console.log("toMyLottery");
    },
    //打开弹框
      toLottery(id){
        console.log("444444444444")
        console.log(id)
        this.luckyId = id
          this.openlottery = true
          this.$refs.lottery.init(this.luckyId);
          //setTimeout(() => { window.eventBus.$emit("ViewMessage", true) }, 1000);
          
      },
    //弹框关闭后获取open值
    OpenLot(e) {
      this.openlottery = e;
    },
  
 
    // 天 时 分 秒 格式化函数
    countDown(time) {
      const now = new Date().valueOf();
      let d1,d2,h1,h2,m1,m2,s1,s2;
      time = (time - now) / 1000;
      let d = parseInt(time / (24 * 60 * 60));
      let day = d < 10 ? "0" + d : d;
       d1 = String(day).split("")[0];
      d2 = String(day).split("")[1];
         if(d2 == '-') d2=0
      //let h = parseInt(((this.seconds / (60 * 60)) % 24) + d * 24);
      let h = parseInt((time / (60 * 60)) % 24);
      let hour = h < 10 ? "0" + h : h;
       h1 = String(hour).split("")[0];
       h2 = String(hour).split("")[1];
       if(h2 == '-') h2=0
      let m = parseInt((time / 60) % 60);
      let minutes = m < 10 ? "0" + m : m;
       m1 = String(minutes).split("")[0];
       m2 = String(minutes).split("")[1];
        if(m2 == '-') m2=0
      let s = parseInt(time % 60);
      let second = s < 10 ? "0" + s : s;
      s1 = String(second).split("")[0];
       s2 = String(second).split("")[1];
       if(s2 == '-') s2=0
    

      return { d1,d2, h1,h2, m1,m2, s1,s2 }
    },
    //定时器没过1秒参数减1
    Time() {
       setInterval(() => {
       this.LotteryList.forEach(v => {
         v.endTime--;
         v.format = this.countDown(v.endTime);
       })
        this.$forceUpdate();
      }, 1000);
    },
     
    }
  };
</script>

<style lang="scss" scoped>
.lottery {
  .title {
    .alltit {
      display: block;
      position: relative;
      p {
        display: inline-block;
        font-family: "Helvetica";
        font-size: 25px;
        font-weight: bold;
        font-stretch: normal;
        line-height: 25px;
        letter-spacing: 0px;
        color: #270645;
      }
      .hovershow {
        display: inline-block;
        margin-bottom: -5px;
        width: 22px;
        height: 21px;
        margin-left: 11px;
      }
      .box {
        display: none;
        position: absolute;
        left: 135px;
        top: 35px;
        margin-top: 5px;
        border-radius: 5px;
        background: #270645;
        width: 408px;
        min-height: 100px;
        z-index: 100;
        padding: 25px 24px 17px 19px;

        &:before {
          // content: "";
          display: block;
          width: 0;
          height: 0;
          border-width: 0 8px 10px;
          border-style: solid;
          border-color: transparent transparent #270645;
          position: relative;
          top: -15px;
          left: -5px;
          margin-top: -20px;
        }
        div {
          color: #ffffff;
          font-size: 10px;
          line-height: 15px;
          text-align: left;
        }
      }
    }
    .hovershow:hover + .box {
      display: block;
    }
    .cont {
      .desc {
        font-family: "Helvetica";
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 30px;
        letter-spacing: 0px;
        color: #270645;
      }
      .linkto {
        span {
          display: block;
          font-family: "Helvetica";
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 30px;
          letter-spacing: 0px;
          color: #270645;
        }
        img {
          width: 31px;
          height: 31px;
          margin-left: 15px;
        }
      }
    }
  }

  .lotterylist {
    display: flex;
    flex-wrap: wrap;
    .card {
      cursor: pointer;
      height: 360px;
      width: 588px;
      background-image: linear-gradient(0deg, #7f1ee2 0%, #3e0881 100%),
        linear-gradient(#6f58d9, #6f58d9);
      background-blend-mode: normal, normal;
      border-radius: 10px;
      margin-right: 20px;
      margin-bottom: 20px;
      .contant {
        margin: 0 125px;
        width: 300px;
        height: 100%;
        // border: 1px solid red;
        text-align: center;
        .title {
          .coinname {
            font-family:Helvetica;
            font-size: 25px;
            font-weight: bold;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #ffffff;
            box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
            margin-left: 10px;
          }
        }
        .prize {
          font-family:Helvetica;
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;

          color: #cccccc;
        }
        .prizeTitle {
          font-family: Helvetica;
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          color: #ffffff;
        }
        .amount {
          font-family: Helvetica;
          font-size: 50px;
          font-weight: bold;
          font-stretch: normal;
          color: #ffdf7e;
        }
        .timer {
          margin-top: 15px;
          .mao {
            font-family: "\5FAE\8F6F\96C5\9ED1";
            font-size: 20px;
            font-weight: normal;
            font-stretch: normal;
            // line-height: 120px;
            letter-spacing: 0px;
            color: #ffffff;
          }
          .bom {
            font-family: "\5FAE\8F6F\96C5\9ED1";
            font-size: 13px;
            font-weight: normal;
            font-stretch: normal;
            // line-height: 120px;
            letter-spacing: 0px;
            color: #ffdf7e;
            text-align: center;
            margin-top: 7px;
          }
          span {
            display: inline-block;
            width: 30px;
            height: 41px;
            background-color: #480e83;
            border-radius: 5px;
            font-family: "\5FAE\8F6F\96C5\9ED1";
            font-size: 20px;
            font-weight: bold;
            font-stretch: normal;
            line-height: 41px;
            letter-spacing: 0px;
            color: #ffffff;
            text-align: center;
          }
        }
        .lotteries {
          margin-top: 10px;
          font-family: Helvetica;
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          color: #ffdf7e;
        }
      }
    }
    .smallcard {
      cursor: pointer;
      height: 360px;
      width: 385px;
      background-image: linear-gradient(0deg, #7f1ee2 0%, #3e0881 100%),
        linear-gradient(#6f58d9, #6f58d9);
      background-blend-mode: normal, normal;
      border-radius: 10px;
      margin-right: 20px;
      margin-bottom: 20px;
      &:nth-child(3n) {
        margin-right: 0;
      }
      .contant {
        margin: 0 35px;
        width: 300px;
        height: 100%;
        // border: 1px solid red;
        text-align: center;
        .title {
          .coinname {
            font-family: Helvetica;
            font-size: 25px;
            font-weight: bold;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #ffffff;
            box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
            margin-left: 10px;
          }
        }
        .prize {
          font-family: Helvetica;
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;

          color: #cccccc;
        }
        .prizeTitle {
          font-family: Helvetica;
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          color: #ffffff;
        }
        .amount {
          font-family: Helvetica;
          font-size: 50px;
          font-weight: bold;
          font-stretch: normal;
          color: #ffdf7e;
        }
        .timer {
          margin-top: 15px;
          .mao {
            font-family: "\5FAE\8F6F\96C5\9ED1";
            font-size: 20px;
            font-weight: normal;
            font-stretch: normal;
            // line-height: 120px;
            letter-spacing: 0px;
            color: #ffffff;
          }
          .bom {
            font-family: "\5FAE\8F6F\96C5\9ED1";
            font-size: 13px;
            font-weight: normal;
            font-stretch: normal;
            // line-height: 120px;
            letter-spacing: 0px;
            color: #cccccc;
            text-align: center;
            margin-top: 7px;
          }
          span {
            display: inline-block;
            width: 30px;
            height: 41px;
            background-color: #480e83;
            border-radius: 5px;
            font-family: "\5FAE\8F6F\96C5\9ED1";
            font-size: 20px;
            font-weight: bold;
            font-stretch: normal;
            line-height: 41px;
            letter-spacing: 0px;
            color: #ffffff;
            text-align: center;
          }
        }
        .lotteries {
          margin-top: 10px;
          font-family: Helvetica;
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          color: #ffdf7e;
        }
      }
    }
  }
}
</style>
